@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
#title-text{
    color: #000;
font-family: Poppins;

font-style: normal;
font-weight: 700;

}